import { db } from "@/firebase"
import { flatten } from "lodash"
import MissionCollection from "@shared/MissionCollection"
import type { Game } from "@/types/game"
import instance from "./axios.service"

import { FirebaseService } from "./firebase.service"

export default class GameService extends FirebaseService {
  // TODO: get rid of this NASTY method when Firebase is ready
  // Don't try to understand it, just contant Andrew
  getGames(
    orgID: string,
    {
      startAt,
      endAt,
      page,
      pageSize
    }: {
      startAt?: string
      endAt?: string
      page: number
      pageSize: number
    }
  ) {
    const ref = db.auxiliary().ref(`org/${orgID}/games`).orderByKey()

    let query = ref

    if (startAt || endAt) {
      if (startAt) {
        query = query.startAt(startAt)
      } else if (endAt) {
        query = query.endAt(endAt)
      }
    }

    if (startAt) {
      query = query.limitToFirst(pageSize + 2)
    } else {
      query = query.limitToLast(endAt ? pageSize + 2 : pageSize + 1)
    }

    return query.once("value").then(snapshot => {
      const value = snapshot.val()

      if (value && (startAt || endAt)) {
        delete value[endAt]
        delete value[startAt]
      }

      if (value) {
        const array = this.normalizeSnapshotToArray(
          value as Record<string, unknown>
        )

        const isLast = array.length < pageSize

        if (!isLast && array.length > pageSize) {
          if (startAt) {
            array.shift()
          }
          if (endAt) {
            array.pop()
          }
        }

        if (array.length === 0) {
          return {
            total: pageSize * (page - 1),
            value: null
          }
        }

        return {
          total: isLast ? pageSize * (page - 1) + array.length : undefined,
          value: array.reverse()
        }
      } else {
        return {
          total: pageSize * (page - 1),
          value
        }
      }
    })
  }

  /** This method is highly discouraged to use, sine it will fetch a tons of data */
  getAllGames(orgID: string): Promise<Game[]> {
    return db
      .auxiliary()
      .ref(`org/${orgID}/games`)
      .orderByKey()
      .once("value")
      .then(snaphot => {
        const value = snaphot.val()
        return value ? this.normalizeSnapshotToArray(value) : []
      })
  }

  getGamesByName(orgID: string, name: string) {
    return db
      .auxiliary()
      .ref(`org/${orgID}/games`)
      .orderByChild("name")
      .startAt(name)
      .endAt(name + "\u{F8FF}")
      .once("value")
      .then(snapshot => {
        const value = snapshot.val()
        return value ? this.normalizeSnapshotToArray(value) : []
      })
  }

  getGamesByRunStatus(orgID: string, runStatus: any) {
    const ref = db
      .auxiliary()
      .ref(`org/${orgID}/games`)
      .orderByChild("runStatus")

    if (Array.isArray(runStatus)) {
      return Promise.all(
        runStatus.map(runStatus => this.getGamesByRunStatus(orgID, runStatus))
      ).then(res => {
        return flatten(res)
      })
    } else {
      return ref
        .equalTo(runStatus)
        .once("value")
        .then(snapshot => {
          const value: Record<string, Game> | null = snapshot.val()
          return value ? this.normalizeSnapshotToArray(value) : []
        })
    }
  }

  getGamesByHost(orgID: string, hostID: string) {
    return db
      .auxiliary()
      .ref(`org/${orgID}/games`)
      .orderByChild("hostUserID")
      .equalTo(hostID)
      .once("value")
      .then(snapshot => {
        const value: Record<string, Game> | null = snapshot.val()
        return value ? this.normalizeSnapshotToArray(value) : []
      })
  }

  getGameByID(orgID: string, gameID: string) {
    return db
      .auxiliary()
      .ref(`org/${orgID}/games/${gameID}`)
      .once("value")
      .then(snapshot => {
        const value: Game | null = snapshot.val()
        return value ? this.normalizeObjectLikeSnapshot(value, gameID) : value
      })
  }

  getGamesByClientID(orgID: string, clientID: string) {
    return db
      .auxiliary()
      .ref(`org/${orgID}/games`)
      .orderByChild("clientID")
      .equalTo(clientID)
      .once("value")
      .then(snapshot => {
        const value: Record<string, Game> | null = snapshot.val()
        return value ? this.normalizeSnapshotToArray(value) : []
      })
  }

  getCompletedGames(orgID: string) {
    return db
      .auxiliary()
      .ref(`org/${orgID}/games`)
      .orderByChild("endTimestamp")
      .startAt(1)
      .once("value")
      .then(snapshot => {
        const value = snapshot.val()
        return this.normalizeSnapshotToArray(value)
      })
  }

  getFutureGames(orgID: string) {
    return db
      .auxiliary()
      .ref(`org/${orgID}/games`)
      .orderByChild("startTimestamp")
      .startAt(Date.now())
      .once("value")
      .then(snapshot => {
        const value = snapshot.val()
        return this.normalizeSnapshotToArray(value)
      })
  }

  // TODO(Andrew): what's the type, perhapts it's intersection of /game and /games data
  updateGames(orgID: string, gameID: string, payload: unknown) {
    return Promise.all([
      db.auxiliary().ref(`org/${orgID}/games/${gameID}`).update(payload),
      db.auxiliary().ref(`org/${orgID}/game/${gameID}`).update(payload)
    ])
  }

  deleteGame(orgID: string, gameID: string) {
    return db
      .auxiliary()
      .ref()
      .update({
        [`org/${orgID}/game/${gameID}`]: null,
        [`org/${orgID}/games/${gameID}`]: null
      })
  }

  updateMissionPositions(
    orgID: string,
    gameID: string,
    payload: Record<string, number>
  ) {
    return db
      .auxiliary()
      .ref(`org/${orgID}/game/${gameID}/missions`)
      .update(payload)
  }

  async listMissions(orgID: string, gameID: string) {
    const snapshot = await db
      .auxiliary()
      .ref(`org/${orgID}/game/${gameID}/missions`)
      .once("value")
    const value = snapshot.val()
    if (value === null) {
      return []
    }
    return MissionCollection.normalize(value || {})
  }

  async unassignGameFromTemplate(game) {
    return await instance({
      method: "patch",
      url: `/game/${game.id}/template-unassign`,
      data: { game }
    })
  }
}
